<template>
  <div>
    <el-radio-group v-model="radio1" size="large">
      <el-radio-button :label="$t('客户发送统计')" />
      <el-radio-button :label="$t('错误码统计')" />
      <el-radio-button :label="$t('签名发送统计')" />
    </el-radio-group>
    <customerSend v-if="radio1 == $t('客户发送统计')"></customerSend>
    <errorCodeStatistics v-if="radio1 == $t('错误码统计')"></errorCodeStatistics>
    <signature v-if="radio1 == $t('签名发送统计')"></signature>
  </div>
</template>

<script>
export default {
  name: "statisticalManage",
};
</script>
<script setup>
import { ref,defineAsyncComponent } from "vue";
// import customerSend from "./customerSend.vue";
// import errorCodeStatistics from "./errorCodeStatistics.vue";
// import signature from "./signature.vue";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const customerSend=defineAsyncComponent(() => import("./customerSend.vue"));
const errorCodeStatistics=defineAsyncComponent(() => import("./errorCodeStatistics.vue"));
const signature=defineAsyncComponent(() => import("./signature.vue"));
const radio1 = ref(t('客户发送统计'));
</script>
<style scoped lang='scss'>
</style>